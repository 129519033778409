<div class="container">
  <h2>
    <span>{{ 'CONSENTS.PAYMENT_OPTIONS.PLANS_AND' | translate }}</span><span>{{ 'CONSENTS.PAYMENT_OPTIONS.PRICINGS' | translate }}</span>
  </h2>
  <h3>
    {{ 'CONSENTS.PAYMENT_OPTIONS.PLEASE_CHOOSE_PAYMENT_OPTION' | translate: {tpValue: consent.patient_cost / 100} }}
  </h3>
  <form class="col s12"
        role="form">
    <ul class="payment-options-list">
      <app-consent-payment-option *ngFor="let paymentOption of consent.payment_options"
                                  [paymentOption]="paymentOption"
                                  (activated)="onOptionActivated($event)"
                                  [active]="paymentOption.id === this.output.payment_option_id"
                                  [loanApplicationDoneEvent]="paymentOption.type == paymentOptionTypes.WISETACK ? loanApplicationDoneEvent : null"
                                  [patient]="paymentOption.type == paymentOptionTypes.WISETACK ? consent.patient : null"
                                  [ngClass]="{
                                    'wisetack-option': paymentOption.type == paymentOptionTypes.WISETACK,
                                    'carecredit-option': paymentOption.type == paymentOptionTypes.CARE_CREDIT,
                                    'wider-option': widerWisetackPaymentOption,
                                    'move-wisetack-right': wisetackAndCareCreditOptionsPresent
                                  }">
      </app-consent-payment-option>
      <app-choose-later *ngIf="allowSkippingPaymentOptionSelection()"
                        (activated)="onOptionActivated($event)"
                        [active]="null === this.output.payment_option_id">
      </app-choose-later>
    </ul>
  </form>
  <div class="row row-extra mt-1"
       *ngIf="!formControl.valid && formControl.touched">
    <ng-container *ngIf="formControl.errors">
      <span class="helper-text invalid"
            *ngIf="formControl.errors.required">{{
        'CONSENTS.VALIDATION.PAYMENT_OPTIONS.REQUIRED' | translate
      }}</span>
    </ng-container>
  </div>
</div>
