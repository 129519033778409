import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { ConsentRequest } from './consent-request.model';
import { ConsentsRequestService } from './consents-request.service';
import { HttpClient } from '@angular/common/http';
import { KioskDeviceService } from '../../kiosk/kiosk-device/kiosk-device.service';
import { Modal } from '../../materialize';
import { Observable } from 'rxjs';
import { PracticeService } from '../../common/practice';
import { SentryErrorHandler } from '../../sentry/sentry.service';
import { SessionTimeoutService } from '../../common/session-timeout/session-timeout.service';
import { PaymentOptionTypes } from '../consent/consent-payment-plans/payment-option/payment-option-types.enum';

@Component({
  selector: 'app-consents-request',
  templateUrl: './consents-request.component.html',
  styleUrls: ['./consents-request.component.scss']
})
export class ConsentsRequestComponent implements OnInit {
  submitted = false;
  cancelled = false;
  consentRequest: ConsentRequest;
  needToAuthorize = false;
  timeoutModal: Modal;
  showQRCode = false;
  qrcodeUrl: string;
  hideInactivityBar = false;

  countDownSessionLeft$: Observable<number> = this.sessionTimeoutService
    .timeoutValue$;

  get showConsent(): boolean {
    return (
      this.consentRequest &&
      !this.submitted &&
      !this.showQRCode &&
      !this.needToAuthorize &&
      !this.cancelled
    );
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private practiceService: PracticeService,
    private kioskDeviceService: KioskDeviceService,
    private sessionTimeoutService: SessionTimeoutService,
    private consentRequestService: ConsentsRequestService,
    private http: HttpClient,
    private errorHandler: SentryErrorHandler
  ) {}

  ngOnInit(): void {
    if (this.kioskDeviceService.isInKiosk()) {
      this.sessionTimeoutService.startSessionForTimeouts(
        ['kiosk', 'video'],
        () => {
          if (!this.sessionTimeoutService.isOnlyManualTimeoutResetActive()) {
            this.openTimeoutModal();
          }
        }
      );
    } else {
      this.sessionTimeoutService.startSessionForTimeouts(['timeout'], () =>
        this.openTimeoutModal()
      );
    }

    if (window.location.pathname.includes('__TEST__')) {
      this.route.params.subscribe((params) => {
        this.practiceService.setPracticeGuid('test1');
        this.practiceService
          .getPracticeData()
          .then((practice) => {
            this.practiceService.setPractice(practice);
          })
          .finally(() => {
            this.setDevConsent(window.location.pathname.includes('tp'));
          });
      });
      return;
    }

    this.route.params.subscribe((params) => {
      this.practiceService.setPracticeGuid(params.practice_guid.toLowerCase());
      this.consentRequestService.setConsentRequestCode(
        params.consent_request_code
      );
      this.initData();
    });
  }

  private async initData(): Promise<any> {
    await this.practiceService.loadPracticeIfNeeded().then(
      () => {
        this.consentRequestService.getConsentRequestData().then(
          (consentRequestResponse) => {
            this.consentRequest = this.handleCareCreditPaymentOptionVisibility(
              consentRequestResponse
            );
            this.showQRCode =
              this.kioskDeviceService.isInKiosk() && this.consentRequest.qrcode;
            if (this.consentRequest.authorize) {
              this.needToAuthorize = true;
            }
            if (this.showQRCode) {
              this.qrcodeUrl = this.consentRequest.qrcode_url;
            }
          },
          (error) => {
            this.errorHandler.captureError(error);
            this.router.navigate([''], { replaceUrl: true });
          }
        );
      },
      (error) => {
        this.errorHandler.captureError(error);
        this.router.navigate([''], { replaceUrl: true });
      }
    );
  }

  onSubmitted(): void {
    this.submitted = true;
  }

  onHideBarChange(event): void {
    this.hideInactivityBar = event;
  }

  onVerifiedInitials(verified: boolean): void {
    if (verified) {
      this.needToAuthorize = false;
    } else {
      if (this.kioskDeviceService.isInKiosk()) {
        this.router.navigate(['kiosk'], { replaceUrl: true });
      } else {
        this.router.navigate([''], { replaceUrl: true });
      }
    }
  }

  onCancelled(): void {
    this.cancelled = true;
    this.consentRequest = null;
  }

  onTimeoutModalOpen(): void {
    this.sessionTimeoutService.allowResetTimeoutOnlyManually();
  }

  onTimeoutModalClose(): void {
    this.sessionTimeoutService.resetTimeoutManually();
  }

  private setDevConsent(isTreatmenPlan: boolean): void {
    this.http
      .get('../../../assets/stubs/treatment-plan-extended.html.stub', {
        responseType: 'text'
      })
      .subscribe((data) => {
        this.consentRequest = {
          authorize: false,
          title: 'Very Long Test Consent Title With Possibility',
          content: data,
          doctor_signs: true,
          copy_patient: false,
          qrcode: false,
          qrcode_url: null,
          force_select_payment_option: false,
          id: 1,
          code: 'BhbeswQLC7V65QKnyl6hnFoqY9euwxdD3jat3sBw',
          patient_cost: 10000,
          patient: {
            id: 100,
            first_name: 'John',
            last_name: 'Doe',
            date_of_birth: '2001-08-14T00:00:00+00:00',
            loan_prequalification: true,
            max_qualified_amount: 100,
            loan_prequalification_status: 'APPROVED'
          },
          payment_options: [
            {
              id: 100,
              title: 'Payment option 1',
              text: 'Payment description 1',
              management_fee: 0,
              discounted_total: 0,
              discount_amount: 0,
              remaining_total: 0,
              loan_amount: 0,
              total_loan_cost: 0,
              external_url: null
            },
            {
              id: 101,
              title: 'Payment option 2',
              text: 'Payment description 2',
              management_fee: 0,
              discounted_total: 0,
              discount_amount: 0,
              remaining_total: 0,
              loan_amount: 0,
              total_loan_cost: 0,
              external_url: null
            },
            {
              id: 103,
              title: 'Payment option 3',
              text: 'Payment description 3',
              management_fee: 0,
              discounted_total: 0,
              discount_amount: 0,
              remaining_total: 0,
              loan_amount: 0,
              total_loan_cost: 0,
              external_url: null
            }
          ],
          status: 0,
          type: 1
        } as ConsentRequest;
      });
  }

  private openTimeoutModal(): void {
    if (!this.timeoutModal) {
      return;
    }

    this.timeoutModal.open();
  }

  private handleCareCreditPaymentOptionVisibility(
    response: ConsentRequest
  ): ConsentRequest {
    const careCredit = response.payment_options.find(
      (option) => option.type === PaymentOptionTypes.CARE_CREDIT
    );
    if (careCredit && !careCredit.external_url) {
      return {
        ...response,
        payment_options: response.payment_options.filter(
          (option) => option.type !== PaymentOptionTypes.CARE_CREDIT
        )
      };
    }

    return response;
  }
}
