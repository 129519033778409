import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { PaymentOptionTypes } from './payment-option-types.enum';
import { WisetackLoanPreQualificationStatuses } from './wisetack-loan-pre-qualification-statuses.enum';
import { PaymentOption } from '../../../consents-request/consent-request.model';
import { environment } from '../../../../../environments/environment';
import { WisetackLoanApplicationDoneEvent } from './wistetack-loan-pre-qualification-done-event.model';
import { Patient } from '../../../../common/patient/patient.model';

@Component({
  selector: 'app-consent-payment-option',
  templateUrl: './payment-option.component.html',
  styleUrls: ['payment-option.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentOptionComponent implements OnInit {
  @Input() paymentOption: PaymentOption;
  @Input() active = false;
  @Input()
  loanApplicationDoneEvent?: WisetackLoanApplicationDoneEvent;
  @Input() patient?: Patient;
  @Output() activated: EventEmitter<PaymentOption> = new EventEmitter<
    PaymentOption
  >();
  isWisetackLoanPreQualificationDone: boolean;
  isWisetackLoanApplicationDone: boolean;
  loanPreQualificationStatus: string;
  loanApplicationStatus: string;
  paymentOptionTypes = PaymentOptionTypes;
  wisetackLoanPreQualificationStatuses = WisetackLoanPreQualificationStatuses;
  wisetackLoanApplicationStatuses = WisetackLoanPreQualificationStatuses;
  readonly environment: typeof environment = environment;
  widerGrid: boolean;
  isTypeWisetack: boolean;
  isTypeCareCredit: boolean;

  ngOnInit(): void {
    this.setPaymentOptionType();
    this.widerGrid =
      (this.isTypeWisetack || this.isTypeCareCredit) &&
      this.environment.wisetack.widerWisetackPaymentOption;
    this.isWisetackLoanPreQualificationDone =
      this.isTypeWisetack && this.patient?.loan_prequalification;
    this.isWisetackLoanApplicationDone =
      this.isTypeWisetack &&
      this.loanApplicationDoneEvent?.preQualificationDone;

    if (this.isWisetackLoanApplicationDone) {
      this.setLoanApplicationStatus();
    }

    if (this.patient?.loan_prequalification) {
      this.setLoanPreQualificationStatus();
    }
  }

  onClick(): void {
    if (
      this.loanPreQualificationStatus ===
        this.wisetackLoanPreQualificationStatuses.NOT_APPROVED ||
      this.loanApplicationStatus ===
        this.wisetackLoanPreQualificationStatuses.ERROR ||
      this.loanApplicationStatus ===
        this.wisetackLoanPreQualificationStatuses.DECLINED
    ) {
      return;
    }
    this.active = true;
    this.activated.emit(this.paymentOption);
  }

  private setPaymentOptionType(): void {
    const paymentOptionType = this.paymentOption.type.toUpperCase();

    this.isTypeWisetack =
      paymentOptionType === this.paymentOptionTypes.WISETACK;
    this.isTypeCareCredit =
      paymentOptionType === this.paymentOptionTypes.CARE_CREDIT;
  }

  private setLoanApplicationStatus(): void {
    if (this.loanApplicationDoneEvent.error) {
      this.loanApplicationStatus = this.wisetackLoanPreQualificationStatuses.ERROR;
      return;
    }
    if (this.loanApplicationDoneEvent.accepted) {
      this.loanApplicationStatus = this.wisetackLoanPreQualificationStatuses.APPROVED;
      return;
    }
    if (!this.loanApplicationDoneEvent.accepted) {
      this.loanApplicationStatus = this.wisetackLoanPreQualificationStatuses.DECLINED;
      return;
    }
  }

  private setLoanPreQualificationStatus(): void {
    if (
      this.patient.loan_prequalification_status ===
      this.wisetackLoanPreQualificationStatuses.DECLINED
    ) {
      this.loanPreQualificationStatus = this.wisetackLoanPreQualificationStatuses.NOT_APPROVED;
      return;
    }
    if (
      this.patient.max_qualified_amount >= this.paymentOption.total_cost_dollars
    ) {
      this.loanPreQualificationStatus = this.wisetackLoanPreQualificationStatuses.APPROVED;
      return;
    }
    if (
      this.patient.max_qualified_amount < this.paymentOption.total_cost_dollars
    ) {
      this.loanPreQualificationStatus = this.wisetackLoanPreQualificationStatuses.PARTIALLY_APPROVED;
      return;
    }
  }
}
