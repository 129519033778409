import {
  AddCardPayload,
  UpdateCardPayload
} from '../../common/clearent/clearent-card-payment/clearent-card-payment.types';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ConsentFlowPage, PaymentOptionHook } from './consent.types';
import {
  ConsentRequest,
  PaymentOption
} from '../consents-request/consent-request.model';
import {
  ConsentSubmit,
  ConsentSubmitAction
} from '../consents-request/consent-submit.model';
import {
  PaymentOperators,
  Practice
} from '../../../app/common/practice/practice.model';
import {
  PaymentPlanFrequency,
  SavePaymentPlanRequest
} from './consent-payment-plan-sign/consent-payment-plan-sign.types';

import { AbstractControl } from '@angular/forms';
import { AlertsService } from '../../common/alerts/alerts.service';
import { ClearentService } from '../../common/clearent/clearent.service';
import { ConsentsRequestService } from '../consents-request/consents-request.service';
import { DeviceService } from '../../common/device-service/device.service';
import { KioskDeviceService } from '../../kiosk/kiosk-device/kiosk-device.service';
import { Modal } from '../../materialize';
import { PaymentPlansService } from '../payment-plan-consent/payment-plan-consent.service';
import { PracticeService } from '../../../app/common/practice';
import { TranslateService } from '@ngx-translate/core';
import { WisetackLoanApplicationDoneEvent } from './consent-payment-plans/payment-option/wistetack-loan-pre-qualification-done-event.model';
import * as clonedeep from 'lodash.clonedeep';
import { map } from 'rxjs/operators';
import { PaymentOptionTypes } from './consent-payment-plans/payment-option/payment-option-types.enum';
import { WINDOW } from '../../common/providers/window';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {
  @Input() consent: ConsentRequest;
  @Output() submitted = new EventEmitter();
  @Output() cancelled = new EventEmitter();
  @ViewChild('body')
  private bodyElement: ElementRef;
  pages: ConsentFlowPage[];
  currentPageIdx = 0;
  agreed: boolean;
  currentPageControl: AbstractControl;
  output: ConsentSubmit = {} as ConsentSubmit;
  isSubmitting: boolean;
  confirmModal: Modal;
  nextPageDisabled: boolean;
  loanPreQualificationDoneEvent: WisetackLoanApplicationDoneEvent;
  private wisetackWarning: boolean;
  confirmWisetackModal: Modal;
  selectedPaymentOption: null | PaymentOption = null;
  practice: Practice;
  rejectConsentAccepted: boolean = false;

  get continueButtonLabel(): string {
    const isWisestack =
      this.pages[this.currentPageIdx] === ConsentFlowPage.WISETACK;
    if (isWisestack && !this.loanPreQualificationDoneEvent) {
      return this.translateService.instant('CONSENTS.BUTTONS.COMPLETE_LATER');
    } else if (isWisestack && this.loanPreQualificationDoneEvent) {
      return this.translateService.instant(
        'CONSENTS.BUTTONS.CONTINUE_TO_SIGNATURE'
      );
    } else if (this.currentPageIdx === this.pages.length - 1) {
      return this.translateService.instant('FORMS.BUTTONS.SUBMIT');
    } else {
      return this.translateService.instant('CONSENTS.BUTTONS.CONTINUE');
    }
  }

  constructor(
    @Inject(WINDOW) private readonly windowRef: Window,
    private translateService: TranslateService,
    private alertsService: AlertsService,
    private consentRequestService: ConsentsRequestService,
    private kioskDeviceService: KioskDeviceService,
    private deviceService: DeviceService,
    private cdRef: ChangeDetectorRef,
    private readonly clearentService: ClearentService,
    private readonly practiceService: PracticeService,
    private readonly paymentPlanService: PaymentPlansService
  ) {}

  ngOnInit(): void {
    this.practice = this.practiceService.getPractice();
    this.output.copy_patient = this.consent.copy_patient;
    this.pages = this.createPageFlow();
  }

  get canGoNext(): boolean {
    return (
      !this.nextPageDisabled &&
      this.pages[this.currentPageIdx] !== ConsentFlowPage.WISETACK
    );
  }

  private createPageFlow(withRejectedPage: boolean = false): ConsentFlowPage[] {
    const pages = [];
    pages.push(ConsentFlowPage.CONTENT);

    if (withRejectedPage) {
      pages.push(ConsentFlowPage.REJECTED);
    } else if (
      this.consent.type === 1 &&
      this.consent.payment_options &&
      this.consent.payment_options.length > 0
    ) {
      pages.push(ConsentFlowPage.PAYMENT_OPTIONS);
    }

    pages.push(ConsentFlowPage.PATIENT_SIGN);

    if (
      this.isClearent() &&
      this.consent.payment_options?.length > 0 &&
      !withRejectedPage
    ) {
      pages.push(ConsentFlowPage.CLEARENT_PAYMENT_PLAN);
    }
    return pages;
  }

  isClearent(): boolean {
    return this.practice?.payments_operator === PaymentOperators.CLEARENT;
  }

  getPageName(): string {
    switch (this.pages[this.currentPageIdx]) {
      case ConsentFlowPage.CONTENT:
        return this.translateService.instant('CONSENTS.CONTENT.TITLE');
      case ConsentFlowPage.PATIENT_SIGN:
        return this.translateService.instant('CONSENTS.PATIENT_SIGN.TITLE');
      case ConsentFlowPage.PAYMENT_OPTIONS:
        return this.translateService.instant('CONSENTS.PAYMENT_OPTIONS.TITLE');
      case ConsentFlowPage.WISETACK:
        return this.translateService.instant(
          'CONSENTS.PAYMENT_OPTIONS.WISETACK.TITLE'
        );
      default:
        return '';
    }
  }

  consentConfirmed(control: AbstractControl): void {
    this.rejectConsentAccepted = control.value;

    this.onPageControlSet(control);
  }

  prevPage(): void {
    if (this.currentPageIdx === 0) {
      this.confirmModal.open();
    } else {
      this.currentPageIdx--;
      this.bodyElement.nativeElement.scrollTop = 0;
    }
  }

  async nextPage(): Promise<void> {
    this.checkPageValidation();
    if (!this.isPageValid()) {
      switch (this.pages[this.currentPageIdx]) {
        case ConsentFlowPage.PAYMENT_OPTIONS:
          this.alertsService.showError(
            this.translateService.instant(
              'CONSENTS.VALIDATION.PAYMENT_OPTIONS.REQUIRED'
            )
          );
          break;
        default:
          this.alertsService.showError(
            this.translateService.instant('CONSENTS.VALIDATION.GENERAL')
          );
      }
      return;
    }

    if (this.wisetackWarning) {
      this.confirmWisetackModal.open();
      return;
    }

    if (this.currentPageIdx < this.pages.length - 1) {
      if (
        this.getCurrentPage() === ConsentFlowPage.PATIENT_SIGN &&
        this.selectedPaymentOption?.type === PaymentOptionTypes.CARE_CREDIT
      ) {
        this.windowRef.open(this.selectedPaymentOption.external_url, '_blank');
        await this.submitWithoutPaymentPlan();

        return;
      }

      if (this.getCurrentPage() === ConsentFlowPage.CONTENT) {
        this.agreed = this.currentPageControl.value;

        if (this.currentPageControl.value === false) {
          if (!this.pages.includes(ConsentFlowPage.REJECTED)) {
            this.pages = this.createPageFlow(true);
          }
        } else if (this.currentPageControl.valid === true) {
          this.pages = this.createPageFlow(false);
        }
      }

      this.currentPageControl = null;
      this.currentPageIdx++;
      this.bodyElement.nativeElement.scrollTop = 0;
    } else {
      await this.submitConsent();
    }
  }

  async submitWithoutPaymentPlan(): Promise<void> {
    if (this.wisetackWarning) {
      this.confirmWisetackModal.open();
      return;
    }
    await this.submitConsent(false);
  }

  getCurrentPage(): ConsentFlowPage {
    return this.pages[this.currentPageIdx];
  }

  onPaymentOptionHook(hook: PaymentOptionHook): void {
    const { activated, paymentOption } = hook;

    this.selectedPaymentOption = paymentOption;

    if (activated === true) {
      const wisetackOption = this.pages.findIndex((element) => {
        return element === ConsentFlowPage.WISETACK;
      });
      if (wisetackOption === -1) {
        const patientSign = this.pages.findIndex((element) => {
          return element === ConsentFlowPage.PATIENT_SIGN;
        });
        this.pages.splice(patientSign, 0, ConsentFlowPage.WISETACK);

        this.pages = this.pages.filter(
          (page) => page !== ConsentFlowPage.CLEARENT_PAYMENT_PLAN
        );
      }
    } else {
      const wisetackOption = this.pages.findIndex((element) => {
        return element === ConsentFlowPage.WISETACK;
      });
      if (wisetackOption !== -1) {
        this.pages.splice(wisetackOption, 1);
      }
      const paymentPlan = this.pages.findIndex((element) => {
        return element === ConsentFlowPage.CLEARENT_PAYMENT_PLAN;
      });
      if (paymentPlan !== -1 && !this.selectedPaymentOption) {
        this.pages.splice(paymentPlan, 1);
      }
      if (
        paymentPlan === -1 &&
        this.selectedPaymentOption &&
        this.isClearent()
      ) {
        this.pages.splice(
          this.pages.length,
          0,
          ConsentFlowPage.CLEARENT_PAYMENT_PLAN
        );
      }

      if (paymentOption.type === PaymentOptionTypes.EXTERNAL_FINANCING) {
        this.pages = this.pages.filter(
          (page) => page !== ConsentFlowPage.CLEARENT_PAYMENT_PLAN
        );
      }
    }
  }

  onPageControlSet(value: AbstractControl): void {
    this.currentPageControl = value;
  }

  private checkPageValidation(): void {
    if (this.currentPageControl) {
      this.currentPageControl.markAllAsTouched();
    }
  }

  private isPageValid(): boolean {
    return this.currentPageControl && this.currentPageControl.valid;
  }

  async submitConsent(createPaymentPlan = true): Promise<any> {
    this.isSubmitting = true;

    try {
      const outputCopy: ConsentSubmit = clonedeep(this.output);

      // save signature attachments, get their guids
      // patient signature
      // append patient id to request
      outputCopy.patient_signature_guid.append(
        'patient_id',
        this.consent.patient.id
      );
      const response = await this.consentRequestService.saveAttachment(
        outputCopy.patient_signature_guid
      );
      outputCopy.patient_signature_guid = response.attachment.guid;

      // doctor's signature
      if (outputCopy.doctor_signature_guid) {
        const docResponse = await this.consentRequestService.saveAttachment(
          outputCopy.doctor_signature_guid
        );
        outputCopy.doctor_signature_guid = docResponse.attachment.guid;
      }

      // when user rejected TP
      if (!this.agreed) {
        outputCopy.action = ConsentSubmitAction.REJECT;
        outputCopy.reject_consent_payload = {
          answers: {
            consent: {
              value: true,
              extra: null
            },
            signature_entity: outputCopy.guardian_name ? 'guardian' : 'patient',
            signature: response.attachment.guid
          }
        };
      }

      await this.consentRequestService.saveConsent(outputCopy);

      if (
        this.isClearent() &&
        this.getCurrentPage() === ConsentFlowPage.CLEARENT_PAYMENT_PLAN &&
        createPaymentPlan
      ) {
        const cardDetails = await this.clearentService
          .getCardPayload()
          .pipe(
            map(({ enabled, payload }) =>
              enabled
                ? ({
                    new_credit_card_detail: {
                      token: payload?.token ?? null,
                      card_name: outputCopy.card.name,
                      zip: outputCopy.card.zipCode
                    }
                  } as AddCardPayload)
                : ({
                    credit_card_detail_id: outputCopy.card.id
                  } as UpdateCardPayload)
            )
          )
          .toPromise();

        const paymentPlanSignatureResponse = await this.paymentPlanService
          .attachSignature(outputCopy.payment_plan_signature)
          .toPromise();

        const savePaymentPlanRequest: SavePaymentPlanRequest = {
          patient_id: this.consent.patient.id,
          signature: paymentPlanSignatureResponse.attachment.guid,
          frequency: PaymentPlanFrequency.Monthly,
          number_of_payments: this.selectedPaymentOption.installments,
          start_date: null,
          total_balance: this.selectedPaymentOption.total_cost_int,
          down_payment: this.selectedPaymentOption.down_payment || null,
          ...cardDetails
        };

        await this.paymentPlanService
          .savePaymentPlanFromTreatmentPlan(
            this.consent.patient.id,
            savePaymentPlanRequest
          )
          .toPromise();
      }

      this.submitted.emit(true);
    } catch (e) {
      this.alertsService.showApiError(e);
    }
    this.isSubmitting = false;
  }

  onNextPageDisabled(nextPageDisabled: boolean): void {
    this.nextPageDisabled = nextPageDisabled;
    this.cdRef.detectChanges();
  }

  onLoanPreQualificationDone(event: WisetackLoanApplicationDoneEvent): void {
    this.loanPreQualificationDoneEvent = event;

    if (event.error || !event.accepted) {
      this.prevPage();
      return;
    }
  }

  onWisetackWarning(event: true): void {
    this.wisetackWarning = event;
  }

  nextPageAndSkipWarning(): void {
    this.wisetackWarning = false;
    this.nextPage();
  }
}
