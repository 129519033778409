import { TerminalType } from '../clearent.types';

export interface ClearentCardPaymentData {
  cards: CreditCardDetail[];
  loading: boolean;
  clearentFormEnabled: boolean;
  clearentInitialized: boolean;
  validationErrors: string;
  terminalType: TerminalType;
  disabled: boolean;
  selectedCard: CreditCardDetail;
}

export interface ClearentCardDetails {
  id?: number;
  token?: string;
  expiry?: string;
  name: string;
  zipCode?: string;
  saveCard?: boolean;
}

export interface CreditCardDetail {
  id: number;
  name: string;
  card_type: TransactionCardType;
  last_four: string;
  token: string;
  expiry: string;
  created_at?: string;
}

export enum TransactionCardType {
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
  AMEX = 'AMERICAN EXPRESS',
  DISCOVER = 'DISCOVER',
  DINERS = 'DINERS CLUB',
  JCB = 'JCB'
}

export interface AddCardPayload {
  new_credit_card_detail: {
    token: string;
    card_name: string;
    zip?: string;
  };
}

export interface UpdateCardPayload {
  credit_card_detail_id: number;
}
