import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_URL } from '../../app.config';
import { Practice } from './practice.model';
import { CookieService } from 'ngx-cookie';
import { PhonePipe } from '../pipes/phone/phone.pipe';
import { sessionStorageSetItemOptional } from '../utils/storage-utils.helper';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PracticeService {
  private practice: Practice;
  private practiceGuid: string;

  constructor(
    private readonly http: HttpClient,
    private readonly cookieService: CookieService,
    private readonly phonePipe: PhonePipe,
    @Inject(API_URL) private readonly apiUrl: string
  ) {
    this.practiceGuid = this.cookieService.get('practice_guid');
    const storedPractice = JSON.parse(sessionStorage.getItem('practice'));
    if (storedPractice) {
      console.log('Restored practice from session storage');
      this.practice = storedPractice;
      this.practiceGuid = this.practice.guid;
    }
  }

  async getPracticeData(): Promise<Practice> {
    return this.http
      .get<Practice>(`${this.apiUrl}/${this.practiceGuid}/practice`)
      .toPromise();
  }

  checkPracticeExist(practiceGuid): Observable<Practice | boolean> {
    return this.http
      .get<Practice>(`${this.apiUrl}/${practiceGuid}/practice`)
      .pipe(catchError(() => of(false)));
  }

  async lazyRefreshPracticeData(): Promise<Practice> {
    if (!this.practice || this.practice.guid !== this.practiceGuid) {
      this.setPractice(await this.getPracticeData());
    } else {
      this.getPracticeData().then((practice) => this.setPractice(practice));
    }

    return Promise.resolve(this.practice);
  }

  /**
   * Loads practice data based on guid if not loaded already.
   */
  async loadPracticeIfNeeded(): Promise<Practice> {
    if (!this.practice || this.practice.guid !== this.practiceGuid) {
      this.setPractice(
        await this.http
          .get<Practice>(`${this.apiUrl}/${this.practiceGuid}/practice`)
          .toPromise()
      );
    }

    return Promise.resolve(this.practice);
  }

  setPractice(practice: Practice): void {
    this.practice = practice;
    this.practiceGuid = practice.guid;
    sessionStorageSetItemOptional('practice', JSON.stringify(this.practice));
  }

  getPractice(): Practice {
    return this.practice;
  }

  setPracticeGuid(guid: string): void {
    this.practiceGuid = guid;
  }

  getPracticeGuid(): string {
    return this.practiceGuid;
  }

  get surchargeEnabled(): boolean {
    return this.getPractice().surcharge_enabled === '1';
  }

  clearPracticeInfo(): void {
    delete this.practice;
    delete this.practiceGuid;
    sessionStorage.removeItem('practice');
  }
}
