import { AbstractControl, FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ConsentRequest,
  PaymentOption
} from '../../consents-request/consent-request.model';

import { ConsentSubmit } from '../../consents-request/consent-submit.model';
import { CustomValidators } from '../../../common/validators/custom-validators.helper';
import { PaymentOperators } from 'src/app/common/practice/practice.model';
import { PaymentOptionHook } from '../consent.types';
import { PaymentOptionTypes } from './payment-option/payment-option-types.enum';
import { PracticeService } from 'src/app/common/practice';
import { WisetackLoanApplicationDoneEvent } from './payment-option/wistetack-loan-pre-qualification-done-event.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-consent-payment-plans',
  templateUrl: './consent-payment-plans.component.html',
  styleUrls: ['./consent-payment-plans.component.scss']
})
export class ConsentPaymentPlansComponent implements OnInit {
  @Input() consent: ConsentRequest;
  @Input() output: ConsentSubmit;
  @Input()
  loanApplicationDoneEvent?: WisetackLoanApplicationDoneEvent;
  @Output() control = new EventEmitter<AbstractControl>();
  @Output() paymentOptionHook = new EventEmitter<PaymentOptionHook>();

  formControl: FormControl;
  paymentOptionTypes = PaymentOptionTypes;
  readonly environment: typeof environment = environment;
  readonly widerWisetackPaymentOption =
    environment.wisetack.widerWisetackPaymentOption;

  wisetackAndCareCreditOptionsPresent: boolean;

  onOptionActivated(paymentOption?: PaymentOption): void {
    if (!this.loanApplicationDoneEvent?.preQualificationDone) {
      this.checkPaymentOptionActivated(paymentOption);
    }

    this.formControl.setValue(paymentOption?.id ?? 0);
  }

  ngOnInit(): void {
    // convert output value, as last selection is encoded as null for backend
    const initValue =
      this.output.payment_option_id !== undefined
        ? this.output.payment_option_id !== null
          ? this.output.payment_option_id
          : 0
        : undefined;

    this.formControl = new FormControl(initValue, CustomValidators.required());
    this.formControl.valueChanges.subscribe((value) => {
      this.output.payment_option_id = value ? value : null;
    });

    if (this.loanApplicationDoneEvent?.preQualificationDone) {
      this.paymentOptionHook.emit({
        activated: false,
        paymentOption: null
      });
    }

    if (this.loanApplicationDoneEvent) {
      if (
        this.loanApplicationDoneEvent.error ||
        !this.loanApplicationDoneEvent.accepted
      ) {
        this.handleDeselectWisetackPaymentOption();
      }
    }

    this.control.emit(this.formControl);

    this.wisetackAndCareCreditOptionsPresent = this.checkForWisetackOrCareCredit();
  }

  allowSkippingPaymentOptionSelection(): boolean {
    return !this.consent.force_select_payment_option;
  }

  private checkForWisetackOrCareCredit(): boolean {
    const { WISETACK, CARE_CREDIT } = this.paymentOptionTypes;
    return (
      this.consent?.payment_options.filter(
        (o) => o.type === WISETACK || o.type === CARE_CREDIT
      ).length > 1
    );
  }

  private checkPaymentOptionActivated(paymentOption: PaymentOption): void {
    switch (paymentOption ? paymentOption.type.toUpperCase() : null) {
      case this.paymentOptionTypes.WISETACK:
        this.paymentOptionHook.emit({
          activated: true,
          paymentOption
        });
        break;
      default:
        this.paymentOptionHook.emit({
          activated: false,
          paymentOption
        });
    }
  }

  private handleDeselectWisetackPaymentOption(): void {
    const WisetackPaymentOption = this.consent.payment_options.find(
      (paymentOption: PaymentOption) =>
        paymentOption.type.toUpperCase() === this.paymentOptionTypes.WISETACK
    );

    if (
      WisetackPaymentOption &&
      (!this.loanApplicationDoneEvent.accepted ||
        this.loanApplicationDoneEvent.maxAmount <
          WisetackPaymentOption.total_cost_dollars)
    ) {
      this.output.payment_option_id = undefined;
      this.formControl = new FormControl(
        undefined,
        CustomValidators.required()
      );
      this.formControl.valueChanges.subscribe((value) => {
        this.output.payment_option_id = value ? value : null;
      });
    }
  }
}
